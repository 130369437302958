import React, { useState } from "react"; // Add this line

import {
  faDiscord,
  faFacebook,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faLocationDot,
  faPhoneVolume,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LogoImage from "../../assets/footer/LOGO-CYBER-KING-1.png";
import LogoVNpay from "../../assets/footer/VNpay.png";
import LogoMomo from "../../assets/footer/Momo.png";
import LogoATM from "../../assets/footer/ATM.png";
import { CloseOutlined } from "@ant-design/icons";
import Privacy from "./Privacy";

const Footer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleSendToMain = (action, data) => {
    if (window.electron.sendToMain) {
      window.electron.sendToMain(action, data);
    }
  };

  const handleOpenLinkContact = (link) => {
    handleSendToMain("openExternalLink", link);
  };

  return (
    <footer className="bg-[#181927] text-white pb-5">
      <div className="container mx-auto flex flex-wrap justify-between px-10">
        <div className="flex flex-col justify-between py-6 space-y-4">
          <div className="cursor-pointer relative top-2 min-w-[140px] flex justify-start">
            <img
              src={LogoImage} // Corrected the variable name to match the import
              alt="Cyber King Logo"
              width={140}
              height={50}
            />
          </div>
          <div className="flex space-x-4 pt-4">
            <img src={LogoVNpay} alt="Payment 3" width={40} height={40} />
            <img src={LogoMomo} alt="Payment 2" width={40} height={40} />
            <img src={LogoATM} alt="Payment 1" width={40} height={40} />
          </div>
        </div>

        <div className="flex flex-col py-6 space-y-2">
          <h3 className="font-bold text-[22px] pb-2">Thông tin liên hệ</h3>
          <p className="flex items-center space-x-2">
            <FontAwesomeIcon icon={faPhoneVolume} width={15} />
            <span className="text-base font-semibold">
              Điện thoại: 0901.552.900
            </span>
          </p>
          <p className="flex items-center space-x-2">
            <FontAwesomeIcon icon={faEnvelope} width={15} />
            <span className="text-base font-semibold">
              Email: sinhle2807@gmail.com
            </span>
          </p>
          <p className="flex items-center space-x-2">
            <FontAwesomeIcon icon={faFacebook} width={15} />
            <span className="text-base font-semibold">
              Fanpage: Fb.com/CyberKingVietNam/
            </span>
          </p>
          <p className="flex items-center space-x-2">
            <FontAwesomeIcon icon={faLocationDot} width={15} />
            <span className="text-base font-semibold">
              Địa chỉ: Số 69 đường số 16, phường 15, quận 11, Tp Hồ Chí Minh
            </span>
          </p>
        </div>

        <div className="flex flex-col py-6 space-y-2">
          <h3 className="font-bold text-[22px] pb-2">Cộng đồng</h3>
          <div
            onClick={() => {
              handleOpenLinkContact(
                "https://www.facebook.com/groups/cyberkingvietnam"
              );
            }}
            className="flex cursor-pointer items-center space-x-2 text-[#D1D1D4] hover:text-white"
          >
            <FontAwesomeIcon icon={faFacebook} width={20} />
            <span className="text-base font-semibold">Facebook</span>
          </div>
          <div
            onClick={() => {
              handleOpenLinkContact("https://discord.gg/TcqaEq2RnJ");
            }}
            className="flex cursor-pointer items-center space-x-2 text-[#D1D1D4] hover:text-white"
          >
            <FontAwesomeIcon icon={faDiscord} width={20} />
            <span className="text-base font-semibold">Discord</span>
          </div>
          <div
            onClick={() => {
              handleOpenLinkContact("https://www.youtube.com/@cyberkingvn69");
            }}
            className="flex cursor-pointer items-center space-x-2 text-[#D1D1D4] hover:text-white"
          >
            <FontAwesomeIcon icon={faYoutube} width={20} />
            <span className="text-base font-semibold">YouTube</span>
          </div>
        </div>

        <div className="flex flex-col py-6 space-y-2">
          <h3 className="font-bold text-[22px] pb-2">Chính sách</h3>
          <div
            onClick={showModal}
            className="text-base cursor-pointer font-semibold text-[#D1D1D4] hover:text-white"
          >
            Chính sách bảo mật
          </div>
          <div
            onClick={showModal}
            className="text-base cursor-pointer font-semibold text-[#D1D1D4] hover:text-white"
          >
            Chính sách bảo hành
          </div>
          <div
            onClick={showModal}
            className="text-base cursor-pointer font-semibold text-[#D1D1D4] hover:text-white"
          >
            Chính sách sử dụng dịch vụ
          </div>
        </div>
      </div>
      <hr className="border-t border-gray-600" />
      <div className="text-center font-medium py-4 text-[#9BA0A0] text-base">
        Copyright © 2024 CYBERKING. All rights reserved.
      </div>

      {isModalOpen && (
        <div
          className={`absolute bg-black bg-opacity-50 z-100 w-full h-full top-0 left-0 flex justify-center items-center`}
        >
          <div className="w-[80%] h-[80%] p-5 bg-white mt-24 rounded-lg relative">
            <button
              className=" absolute top-0 right-2 font-bold text-2xl text-black opacity-70 hover:opacity-100"
              onClick={handleClose}
            >
              <CloseOutlined />
            </button>
            <Privacy />
          </div>
        </div>
      )}
    </footer>
  );
};

export default Footer;
