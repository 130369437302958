import axiosServer from "../../axios/axiosServer";
import APIs from "../../constant/endpoint";

export const toggleWishlist = async ({ steamId, isWishlist }) => {
  const res = await axiosServer.post(APIs.toggleWishlist, {
    steamId,
    isWishlist,
  });
  return res.data;
};

export const getAllWishlist = async (
  { pageParam },
  type,
  pageSize,
  searchParams
) => {
  const res = await axiosServer.get(APIs.mygame(type), {
    params: {
      page: pageParam,
      pageSize,
      searchParams,
    },
  });
  return res.data;
};
