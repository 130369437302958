import React from "react";
import { Button, Image } from "antd";
import { Link } from "react-router-dom";
import iconSteam from "../../assets/icons/home/Steam_icon_logo 1.png";
import iconGame from "../../assets/icons/home/joystick 1.png";
import iconPriceTag from "../../assets/icons/price-tag 1.png";
const GameCard = ({ game, onToggleWishlist, formatCurrency }) => {
  return (
    <div
      key={game.id}
      className="w-full md:max-w-[15rem] lg:max-w[15rem] sm:max-w-[14rem] xs:max-w-[13rem] bg-[#2e2c3d] rounded-lg overflow-hidden sm:mb-1 lg:mb-0 xs:mb-8 md:mb-3 flex justify-center"
    >
      <div className="relative bg-radiant-gradient rounded-lg game-card">
        <div className="relative w-full overflow-hidden rounded-lg">
          <Link to={`/xemgame/${game.id}`} className="w-full h-full">
            <img
              src={game.images[0]}
              alt={game.tengame}
              className="object-cover w-full h-[10rem]  transform transition-transform duration-300 hover:scale-110"
            />
          </Link>
        </div>
        <div className="p-6 lg:px-6 lg:py-2 md:px-4 md:py-2 sm:px-3 sm:py-2">
          <div className="flex justify-between items-start gap-1.25 pb-2 border-b border-white/50 text-[12px]">
            <div className="flex items-center">
              <div className="h-8 w-8 flex items-center lg:w-[2.5rem] lg:h-[2.5rem]">
                <Image src={iconSteam} alt="Steam Icon" preview={false} />
              </div>
              <div className="pl-2">
                <p className="text-[12px] text-white whitespace-nowrap">
                  Nền tảng
                </p>
                <span className="whitespace-nowrap">Steam</span>
              </div>
            </div>
            <div className="flex items-center">
              <div className="h-8 w-8 flex items-center lg:w-[2.5rem] lg:h-[2.5rem]">
                <Image src={iconGame} alt="Game Icon" preview={false} />
              </div>
              <div className="pl-2">
                <p className="text-[12px] text-white whitespace-nowrap">
                  Chế độ
                </p>
                <span className="whitespace-nowrap text-ellipsis max-w-[50px] overflow-hidden">
                  {game.chedo}
                </span>
              </div>
            </div>
          </div>
          <div className="mt-2 flex items-center">
            <div className="min-h-12">
              <h4 className="text-[16px]">
                <Link
                  to={`/xemgame/${game.id}`}
                  className="hover:text-blue-400 h-[3rem]  lg:text-base mt-4"
                >
                  {game.tengame}
                </Link>
              </h4>
            </div>
          </div>
          <div className="flex items-center justify-between lg:mt-4 md:mt-2 ">
            <div></div>
            <div>
              <Button
                onClick={() => onToggleWishlist(game)}
                size="small"
                className={`rounded-3xl text-base ${
                  game.isWishlist
                    ? "text-red-500 bg-blue-950"
                    : "bg-blue-950 text-white"
                }`}
                data-id={game.id}
                icon={<i className="fas fa-heart" />}
              >
                <span className="text-white wishlistCount sm:text-sm md:text-md lg:text-lg">
                  {game.numberWishlist ?? 0}
                </span>
              </Button>
            </div>
          </div>
          <div className="flex justify-between items-center pt-2 mb-2">
            <div className="flex items-center">
              <Image src={iconPriceTag} alt="Price Tag Icon" preview={false} />
              <span className="pl-2 text-base text-white font-semibold">
                Giá thuê
              </span>
            </div>
            <div className="">
              <span className="text-base font-semibold text-[#7ED0FF]">
                {formatCurrency(game.giathue)}đ/giờ
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameCard;
