import { useQuery } from "@tanstack/react-query";
import {
  getAllGame,
  getFreeGame,
  getGameDetail,
  getMostWishlistGame,
} from "../../../apis/game/game";
import { queryContants } from "../../../constant";
import { getAllRamdomGame } from "../../../apis/orders/orders";
import { useInfinitePageQuery } from "../infinitePage.query";

export const useAllGameQuery = (pageSize, searchFilterParams, sort) => {
  return useInfinitePageQuery({
    queryKey: [queryContants.GET_GAME, JSON.stringify(searchFilterParams)],
    queryFn: (props) => getAllGame(props, pageSize, searchFilterParams, sort),
  });
};

export const useGetGameDetailQuery = (id) => {
  return useQuery({
    queryKey: [queryContants.GET_GAME_DETAIL, id],
    queryFn: () => getGameDetail(id),
  });
};

export const useGetAllRamdomGameQuery = (isUser) => {
  const accessToken = localStorage.getItem("accessToken");
  return useQuery({
    queryKey: [queryContants.GET_ALL_RAMDOM_GAME],
    queryFn: getAllRamdomGame,
    enabled: !!accessToken && isUser,
  });
};

export const useGetMostWishListGameQuery = () => {
  return useQuery({
    queryKey: [queryContants.GET_MOST_WISHLIST_GAME],
    queryFn: getMostWishlistGame,
  });
};

export const useGetFreeListGameQuery = () => {
  return useQuery({
    queryKey: [queryContants.GET_FREE_GAME],
    queryFn: getFreeGame,
  });
};
