import { useInfiniteQuery } from "@tanstack/react-query";
import { useMemo } from "react";

export const useInfinitePageQuery = ({ queryKey, queryFn, ...props }) => {
  const queryData = useInfiniteQuery({
    queryKey,
    queryFn,
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) =>
      lastPage.page > pages[0].total / lastPage.pageSize
        ? undefined
        : Number(lastPage.page) + 1,
    ...props,
  });
  const allData = useMemo(
    () => queryData.data?.pages?.map((page) => page.data).flat(),
    [queryData.data]
  );

  return { ...queryData, data: allData };
};
