export const VIPKey = {
  silver: "1",
  gold: "2",
  diamond: "3",
};

export const VipDataMock = [
  {
    id: 1,
    name: "VIP Bạc",
    color: "bg-gradient-to-b from-[#999999] to-[#FFFFFF]",
    price: 99000,
    reward_price: 250000,
    title:
      '["Trải nghiệm miễn phí tất cả các game bản quyền trong thời gian 1 tuần.", "Được ghim tối đa 1 tài khoản."]',
    img: "https://firebasestorage.googleapis.com/v0/b/cyberkingvn-be.appspot.com/o/Silver.png?alt=media&token=b2b53721-9587-4681-a401-8fc310fdf0c5",
    gift_img:
      "https://firebasestorage.googleapis.com/v0/b/cyberkingvn-be.appspot.com/o/giftBoxSilver.svg?alt=media&token=d090a225-bcde-401e-b927-ccebab392242",
    period: 7,
    pin_count: 1,
    is_show: 1,
  },
  {
    id: 2,
    name: "VIP Vàng",
    color: "bg-gradient-to-b from-[#FFDC83] to-[#FFEFC5]",
    price: 699000,
    reward_price: 1500000,
    title:
      '["Trải nghiệm miễn phí tất cả các game bản quyền trong thời gian 2 tháng.", "Được ghim tối đa 2 tài khoản."]',
    img: "https://firebasestorage.googleapis.com/v0/b/cyberkingvn-be.appspot.com/o/Gold.png?alt=media&token=d0a866c7-c560-4b11-9024-b7ce085eeb3a",
    gift_img:
      "https://firebasestorage.googleapis.com/v0/b/cyberkingvn-be.appspot.com/o/giftBoxGold.svg?alt=media&token=4c74c432-e2f1-4612-921d-337f98b8cb8b",
    period: 60,
    pin_count: 2,
    is_show: 1,
  },
  {
    id: 3,
    name: "VIP Kim Cương",
    color: "bg-gradient-to-b from-[#7C93FF] to-[#D3E8FF]",
    price: 3499000,
    reward_price: 15000000,
    title:
      '["Chơi miễn phí tất cả các game bản quyền mới nhất trong thời gian 1 năm.", "Được ghim tối đa 3 tài khoản.", "Mua toàn bộ game bản quyền với giá rẻ hơn lên đến 80%.", "Nhận hàng ngàn ưu đãi và khuyến mãi khác."]',
    img: "https://firebasestorage.googleapis.com/v0/b/cyberkingvn-be.appspot.com/o/Diamond.png?alt=media&token=056700a8-a3fd-41ff-9b36-05fc8610cc6d",
    gift_img:
      "https://firebasestorage.googleapis.com/v0/b/cyberkingvn-be.appspot.com/o/giftBoxDiamond.svg?alt=media&token=af0c91d3-9c44-4571-a834-ab42f80f9780",
    period: 365,
    pin_count: 3,
    is_show: 1,
  },
  {
    id: 4,
    name: "Vip Trải Nghiệm",
    color: "bg-gradient-to-b from-[#999999] to-[#FFFFFF]",
    price: 0,
    reward_price: 0,
    title: "Vip Trải Nghiệm",
    img: "https://firebasestorage.googleapis.com/v0/b/cyberkingvn-be.appspot.com/o/Silver.png?alt=media&token=b2b53721-9587-4681-a401-8fc310fdf0c5",
    gift_img:
      "https://firebasestorage.googleapis.com/v0/b/cyberkingvn-be.appspot.com/o/giftBoxSilver.svg?alt=media&token=d090a225-bcde-401e-b927-ccebab392242",
    period: 3,
    pin_count: 0,
    is_show: 0,
  },
];
