import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App.js";
import Titlebar from "./components/Titlebar/Titlebar.js";
import { ContextProvider } from "./context/Provider.js";
import { queryContants } from "./constant/queries.js";

// Create a client
const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, retry: 1 } },
});

// Set specific options for the specific query key
queryClient.setQueryDefaults(queryContants.GET_ME ?? "GET_ME", {
  refetchOnWindowFocus: true,
  refetchInterval: 60000,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Titlebar />
      <ContextProvider>
        <div className="h-screen">
          <App />
        </div>
      </ContextProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
