import axiosServer from "../../axios/axiosServer";
import APIs from "../../constant/endpoint";

export const getAllGame = async (
  { pageParam },
  pageSize,
  searchFilterParams
) => {
  const res = await axiosServer.get(APIs.getAllGame, {
    params: {
      page: pageParam,
      pageSize,
      searchParams: searchFilterParams?.search,
      sort: searchFilterParams?.sort,
    },
  });
  return res.data;
};

export const getGameDetail = async (id) => {
  const res = await axiosServer.get(APIs.getGameDetails(id));
  return res.data;
};

export const createBuyGame = async (data) => {
  const res = await axiosServer.post(APIs.createBuyGame, data);
  return res.data;
};

export const getRockstarOTP = async (email) => {
  const res = await axiosServer.get(APIs.getRockstarOTP(email));
  return res.data;
}

export const getMostWishlistGame = async () => {
  const res = await axiosServer.get(APIs.getMostWishlistGame);
  return res.data;
};

export const getFreeGame = async () => {
  const res = await axiosServer.get(APIs.getFreeGame);
  return res.data;
};