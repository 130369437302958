import { memo, useEffect, useState } from "react";
import { Flex, message } from "antd";
import { debounce } from "lodash";
import InputOTP from "../InputOTP/InputOTP";
import {
  useSendOtpMutation,
  useVerifyOTPMutation,
} from "../../../store/mutations/auth.mutate";
import useCooldown from "../../../hook/useCooldown";
import generateTimeString from "../../../utils/generateTime";
import { useNavigate } from "react-router-dom";

const OTPRegister = ({ email, onFinish }) => {
  const cooldownPeriod = 600000;
  const navigate = useNavigate();
  const [OTP, setOTP] = useState("");
  const { mutateAsync, isPending, error } = useVerifyOTPMutation();
  const [isCooldown, startCooldown, remainingTime, resetCooldown] = useCooldown(cooldownPeriod);
  const {
    mutateAsync: sendOTPByEmail,
    isPending: isSending,
    error: OTPError,
  } = useSendOtpMutation();

  useEffect(() => {
    startCooldown();
  }, []);

  const handleChangeOTPInput = (otpValue) => {
    setOTP(otpValue);
  };

  const verifyOtpTimes = async () => {
    if (email && OTP.length === 6)
      mutateAsync({ otp: OTP, email })
        .then(() => {
          if (onFinish) {
            onFinish(true);
            message.success(
              "Xác thực tài khoản thành công, vui lòng nhập mật khẩu mới"
            );
          } else {
            message.success(
              "Xác thực tài khoản thành công, trờ về trang đăng nhập"
            );
            navigate("/login");
          }
        })
        .catch(() => setOTP(""));
  };

  const handleSendOTP = debounce(
    async (e) => {
      e.preventDefault();
      sendOTPByEmail(email)
        .then(() => {
          resetCooldown();
          message.success("Mã OTP đã được gửi lại");
        })
        .catch(() => setOTP(""));
    },
    [1000]
  );

  return (
    <Flex
      className="size-full w-full"
      align="center"
      justify="center"
      gap={10}
      vertical
    >
      <h4 className="mb-0">Vui Lòng Xác Nhận OTP</h4>
      <p className="text-center px-4 mb-0">
        Nhập mã OTP đã được gửi đến email {email} của bạn để xác nhận
      </p>
      {isCooldown && <p className="mb-0">
        Mã OTP sẽ hết hiệu lực trong vòng{" "}
        <b>{generateTimeString(remainingTime)}</b>
      </p>}  
      <InputOTP
        type="text"
        length={6}
        onChange={handleChangeOTPInput}
        value={OTP}
        autoFocus
        disabled={isPending || isSending}
        isNumberInput
        className="border-none flex overflow-hidden rounded-lg w-full gap-2 justify-center py-2"
      />
      <p className="mb-1">
        Không nhận được mã OTP ?{" "}
        <button
          onClick={handleSendOTP}
          className={`!text-[#74767d] ${
            isSending ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={isSending}
        >
          Gửi lại
        </button>
      </p>
      <button
        onClick={verifyOtpTimes}
        className="btn"
        disabled={isPending || isSending}
      >
        {isPending ? "Đang xử lý" : "Xác nhận OTP"}
      </button>
      {error?.response?.data ?? error?.message ? (
        <p className="mb-0">{error?.response?.data ?? error?.message}</p>
      ) : (
        <></>
      )}
      {OTPError?.response?.data ?? OTPError?.message ? (
        <p className="mb-0">{OTPError?.response?.data ?? OTPError?.message}</p>
      ) : (
        <></>
      )}
    </Flex>
  );
};

export default memo(OTPRegister);
