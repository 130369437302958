import React, { useState } from "react";
import { domainAutoLogin } from "../constant";

const SteamLogin = () => {
  const [message, setMessage] = useState("");
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  const isLoggedIn = false;

  const handleSteamLogin = async () => {
    try {
      const response = await fetch(`${domainAutoLogin}/login-steam`, {
        method: "GET", // Thay đổi thành phương thức GET
        credentials: "include", // Đảm bảo bao gồm thông tin xác thực trong yêu cầu
      });
      const data = await response.text(); // Sử dụng response.text() để nhận thông báo từ phản hồi
      setMessage(data);
    } catch (error) {
      setMessage("Đã xảy ra lỗi khi đăng nhập Steam");
      console.error("Lỗi khi gửi yêu cầu đăng nhập Steam:", error);
    }
  };

  return (
    <div>
      {isLoggedIn ? (
        <p>You are logged in.</p>
      ) : (
        <div>
          <h2>Steam Login</h2>
          <button onClick={handleSteamLogin}>Login with Steam</button>
          {message && <p>{message}</p>}
        </div>
      )}
    </div>
  );
};

export default SteamLogin;
