export const queryContants = {
  GET_ME: "GET_ME",
  GET_VIP: "GET_VIP",
  //game
  GET_GAME: "GET_GAME",
  GET_GAME_DETAIL: "GET_GAME_DETAIL",
  GET_ALL_RAMDOM_GAME: "GET_ALL_RAMDOM_GAME",
  GET_MOST_WISHLIST_GAME: "GET_MOST_WISHLIST_GAME",
  GET_FREE_GAME: "GET_FREE_GAME",
  //wishlist
  GET_WISHLIST: "GET_WISHLIST",

  //order
  GET_ALL_ORDERS: "GET_ALL_ORDERS",
  GET_ALL_PIN_ORDER: "GET_ALL_PIN_ORDER",

  //payment
  GET_PAYMENT_HISTORY: "GET_PAYMENT_HISTORY",
  GET_AMOUNT_HISTORY: "GET_AMOUNT_HISTORY",
  GET_VIP_ORDER: "GET_VIP_ORDER",

  //app
  GET_BANNER: "GET_BANNER",
  GET_NET_STATUS: "GET_NET_STATUS",
};
