import ReactDOM from "react-dom";
import { LoadingIcon } from "../../../assets/icons";

function LoadingFullscreen({ className, loading, content }) {
  return ReactDOM.createPortal(
    <div
      className={`${
        loading ? "fixed" : "invisible"
      } w-full h-full top-7 left-0 bg-bgOverlay z-50 flex flex-col pb-10 justify-center items-center ${className}`}
    >
      <img className="spinner" src={LoadingIcon} alt="spin icon" />
      <p>{content}</p>
    </div>,
    document.getElementById("loading")
  );
}

export default LoadingFullscreen;
