import { useQuery } from "@tanstack/react-query";
import { getMe, getVip } from "../../apis/auth";
import { queryContants } from "../../constant";

export const useGetMeQuery = () => {
  const accessToken = localStorage.getItem("accessToken");
  return useQuery({
    queryKey: [queryContants.GET_ME],
    queryFn: getMe,
    enabled: !!accessToken,
    refetchInterval: 30000,
  });
};
export const useGetVipQuery = () => {
  const accessToken = localStorage.getItem("accessToken");
  return useQuery({
    queryKey: [queryContants.GET_VIP],
    queryFn: getVip,
    enabled: !!accessToken,
  });
};
