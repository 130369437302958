import { memo, useEffect, useState } from "react";
import { Flex } from "antd";
import PopupConfirm from "../PopupConfirm/PopupConfirm";
import Logo from "../../assets/images/cyber.png";
import {
  Exit,
  Fullscreen,
  Hide,
  Minimize,
  telephone,
} from "../../assets/icons";

const loginwindow = "app-loginWindow";
const mainWindow = "app-mainWindow";

const Titlebar = () => {
  // const location = useLocation();
  const [fullScreen, setFullScreen] = useState(false);
  const [isExit, setIsExit] = useState(false);
  
  useEffect(() => {
    if (window?.electron?.receiveFromMain) {
      window.electron.receiveFromMain(
        "fullscreen-state-changed",
        (isFullScreen) => setFullScreen(!!isFullScreen)
      );
    }
  }, [])

  const isLogin = window.location.pathname === "/login";
  const channel = (isLogin ? loginwindow : mainWindow) + "-";
  // login
  const handleToggleFullscreen = () => {
    // toggle-fullscreen
    if (window.electron.sendToMain) {
      window.electron.sendToMain("toggle-fullscreen");
    }
  };

  const handleSendToMain = (action, data) => {
    if (window.electron.sendToMain) {
      window.electron.sendToMain(action, data);
    }
  };

  const handleOpenLinkContact = (e) => {
    e.preventDefault();
    handleSendToMain("openExternalLink", "https://discord.gg/TcqaEq2RnJ");
  };

  const handleToggleModalExit = () => {
    setIsExit(!isExit)
  }

  return (<>
  <PopupConfirm
  className={!isLogin ? '' : 'min-w-[96%]'}
  visible={isExit}
  onClose={handleToggleModalExit}
  title='Thoát Cyberking App'
  content='Bạn có chắc muốn thoát Cyberking App ?'
  buttons={[
    {
      label: "Thoát",
      className:
        "px-4 py-2 text-sm font-medium text-white bg-[#65C4DF] border border-gray-300 rounded-[30px] shadow-sm opacity-70 hover:opacity-100",
      onClick: () => {handleSendToMain(channel + "exit"); handleToggleModalExit()},
    },
    {
      label: "Ẩn cửa sổ",
      className:
        "px-4 py-2 text-sm font-medium text-white bg-[#65C4DF] border border-gray-300 rounded-[30px] shadow-sm opacity-70 hover:opacity-100",
      onClick: () => {handleSendToMain('hide-window'); handleToggleModalExit();},
    },
    {
      label: "Trờ về",
      className:
        "px-4 py-2 text-sm font-medium text-white bg-[#65C4DF] border border-gray-300 rounded-[30px] shadow-sm opacity-70 hover:opacity-100",
      onClick: handleToggleModalExit,
    },
  ]}
  />
  {!!window.electron ? (
    <header
      onDoubleClick={() => handleSendToMain("toggle-fullwindow")}
      className="flex justify-between top-0 w-full z-[9999] border-b border-b-[#565656] title-bar__raggable-area"
    >
      <img
        className="w-[14px] h-[14px] my-[6px] ml-[6px]"
        src={Logo}
        alt="titlebar"
      />
      <Flex className="title-bar__no-drag">
        <div
          onClick={handleOpenLinkContact}
          className="flex justify-center gap-1 items-center group cursor-pointer hover:bg-[#565656] text-[#D9D9D9] px-2"
        >
          <img className="h-4 w-4" src={telephone} alt="titlebar-icon" />
          <span className="group-hover:text-white font-bold text-sm">
            Liên hệ hỗ trợ trực tuyến
          </span>
        </div>
        <Flex
          onClick={() => handleSendToMain(channel + "minimize")}
          className="group cursor-pointer w-8 hover:bg-[#565656]"
          justify="center"
          align="center"
        >
          <Hide className="group-hover:fill-white" />
        </Flex>
        {!isLogin && (
          <Flex
            onClick={handleToggleFullscreen}
            className="group cursor-pointer w-8 hover:bg-[#565656]"
            justify="center"
            align="center"
          >
            {fullScreen ? (
              <Minimize className="group-hover:fill-white" />
            ) : (
              <Fullscreen className="group-hover:fill-white" />
            )}
          </Flex>
        )}

        <Flex
          onClick={!isLogin ?  handleToggleModalExit : () => handleSendToMain(channel + "exit")}
          className="group cursor-pointer w-8 hover:bg-[#565656] text-sm"
          justify="center"
          align="center"
        >
          <Exit className="group-hover:fill-white" />
        </Flex>
      </Flex>
    </header>
  ) : (
    <></>
  )}
  </>);
};

export default memo(Titlebar);
