import { queryContants } from "../../../constant";
import { getAllWishlist } from "../../../apis/game/wishlist";
import { useInfinitePageQuery } from "../infinitePage.query";

export const useAllWishlistQuery = (type, pageSize, searchParams) => {
  return useInfinitePageQuery({
    queryKey: [queryContants.GET_WISHLIST, type, searchParams],
    queryFn: (props) => getAllWishlist(props, type, pageSize, searchParams),
  });
};
