const Minimize = ({ className }) => {
  return (
    <svg
      className={`size-[10px] fill-[#D9D9D9]  ${className}`}
      viewBox="0 0 10 10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.714286 2.14286L0.714286 9.28571H0L3.12224e-07 2.14286H0.714286Z" />
      <path d="M0 9.28571H7.14286V10H2.55477e-07L0 9.28571Z" />
      <path d="M7.85714 2.85714V10H7.14286V2.85714H7.85714Z" />
      <path d="M0.714286 2.14286H7.85714V2.85714H0.714286V2.14286Z" />
      <path d="M2.14286 6.24448e-08H9.28571V0.714286H2.14286V6.24448e-08Z" />
      <path d="M9.28571 7.14286V6.24448e-08L10 0V7.14286H9.28571Z" />
      <path d="M2.14286 0.714286L2.85714 0.714286V2.85714H2.14286V0.714286Z" />
      <path d="M7.85714 7.14286H10V7.85714H7.85714V7.14286Z" />
    </svg>
  );
};

export default Minimize;
