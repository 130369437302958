const Fullscreen = ({ className }) => {
  return (
    <svg
      className={`size-[10px] fill-[#D9D9D9]  ${className}`}
      viewBox="0 0 10 10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0H9.09091V0.909091H0V0Z" />
      <path d="M0 0H0.909091V9.09091H0V0Z" />
      <path d="M0 9.09091H9.09091V10H0V9.09091Z" />
      <path d="M9.09091 0H10V10H9.09091V0Z" />
    </svg>
  );
};

export default Fullscreen;
