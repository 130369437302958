import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  changePasswordByOTP,
  login,
  sendOTP,
  updateVip,
  verifyOTP,
} from "../../apis/auth";
import { queryContants } from "../../constant";

export const useLoginMutation = () => {
  return useMutation({
    mutationFn: login,
  });
};

export const useVerifyOTPMutation = () => {
  return useMutation({
    mutationFn: verifyOTP,
  });
};

export const useSendOtpMutation = () => {
  return useMutation({
    mutationFn: sendOTP,
  });
};

export const useChangePasswordByOTPMutation = () => {
  return useMutation({
    mutationFn: changePasswordByOTP,
  });
};

export const useUpdateVipMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateVip,
    onSuccess: () => {
      queryClient.invalidateQueries([queryContants.GET_ME]);
    },
  });
};
