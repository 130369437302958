import io from "socket.io-client";
import { electronConstants, wsDomain } from "../constant";

class SocketConnection {
  constructor() {
    if (!SocketConnection.instance) {
      SocketConnection.instance = this;

      const token = localStorage.getItem("accessToken");

      this.socket = io(wsDomain, {
        auth: {
          token,
        },
        transports: ["websocket", "polling"],
        reconnection: true,
        reconnectionAttempts: Infinity,
        reconnectionDelay: 1000,
      });

      this.socket.on("connect", () => {
        console.log("Connected to WebSocket server");
      });

      this.socket.on("connect_error", (error) => {
        console.error("WebSocket connection error:", error);
      });

      this.socket.on("disconnect", () => {
        if (window.electron.sendToMain) {
          window.electron.sendToMain(electronConstants.LOG_OUT_STEAM);
        }
        console.log("Disconnected from WebSocket server");
      });

      SocketConnection.instance = this;
    }
    return SocketConnection.instance;
  }

  sendMessage() {
    this.socket.emit(
      "send_message",
      {
        message: "Hello World!",
      },
      (data) => {
        console.log("Server response:", data);
      }
    );
  }

  on(eventName, callback) {
    this.socket.on(eventName, callback);
  }

  emit(eventName, data, callback) {
    if (typeof callback === "function") {
      this.socket.emit(eventName, data, callback);
    } else {
      this.socket.emit(eventName, data);
    }
  }

  disconnect() {
    this.socket.disconnect();
  }

  static getInstance() {
    if (!SocketConnection.instance) {
      SocketConnection.instance = new SocketConnection();
    }
    return SocketConnection.instance;
  }
}

SocketConnection.instance = null;

export const socket = SocketConnection.getInstance();
