import { useRef, useLayoutEffect, memo } from "react";
import { usePrevious } from "../../../hook/usePrevious";

const SingleCharacterInput = (props) => {
  const { focus, autoFocus, className, ...rest } = props;
  const inputRef = useRef(null);
  const prevFocus = usePrevious(!!focus);

  useLayoutEffect(() => {
    if (inputRef.current) {
      if (focus && autoFocus) {
        inputRef.current.focus();
      }
      if (focus && autoFocus && focus !== prevFocus) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }
  }, [autoFocus, focus, prevFocus]);

  return (
    <input
      className={`no-spin text-center text-3xl font-medium bg-[#1f2029] w-[60px] h-[80px] 
  focus:outline-none focus:border-blue focus:border text-[#c4c3ca] rounded-md ${className}`}
      ref={inputRef}
      {...rest}
    />
  );
};

export default memo(SingleCharacterInput);
