import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { StateContext } from "../../context/reducer";
import { ConfigProvider, Popover } from "antd";
import AccountDropdown from "../Common/AccountDropdown/AccountDropdown";
import accountIcon from "../../assets/icons/header/user.png";

import { electronConstants, queryContants } from "../../constant";
import { useQueryClient } from "@tanstack/react-query";
import { useGetVipQuery } from "../../store/queries/auth.query";
// import { getRockstarOTP } from "../../apis/game/game";

const Header = ({ navItems }) => {
  const {
    state: { user },
  } = useContext(StateContext);
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();
  const { data: vipData } = useGetVipQuery();

  // const [mailtp, setMailtp] = useState('');

  const formatCurrency = (amount) => {
    if (!amount) return "0";
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const getVipRewardStatus = (isVip) => {
    const vip = vipData?.find((item) => item.id === JSON.parse(isVip));
    return (
      vip && (
        <div
          className={`flex items-center ${vip.color} bg-clip-text text-fill-transparent`}
        >
          <div> {vip.name}</div>
          <img src={vip.img} alt={vip.name} className="h-6 w-6 ml-2" />
        </div>
      )
    );
  };

  const goBack = () => {
    navigate(-1);
  };

  const handleNavigate = (link) => {
    navigate(link);
  };

  // const handle = () => {
  //   getRockstarOTP(mailtp).then((res) => {
  //     console.log('res', res);
  //     alert(JSON.stringify(res))
  //   })
  // }

  useEffect(() => {
    if (window?.electron?.receiveFromMain) {
      window.electron.receiveFromMain(
        electronConstants.RELOAD_PROFILE,
        (isReload) => {
          if (isReload) queryClient.invalidateQueries(queryContants.GET_ME);
        }
      );
    }
  }, [])

  return (
    <>
      {user ? (
        <div
          className={`w-[calc(100%-8px)] border-b-[1px] border-gray-500 fixed top-[${
            !!window.electron ? "27px" : "0px"
          }] z-50 bg-transparent backdrop-blur-3xl`}
        >
          <nav className="flex items-center max-h-24">

            {/* <Flex vertical gap={8}>
            <input value={mailtp} onChange={(e) => setMailtp(e.target.value)}/>
            <button onClick={handle}>okehehe</button>
            </Flex> */}

            {/* Back button */}
            <span
              className="pt-0 pb-0 pl-6 lg:text-2xl sm:text-lg md:text-lg text-white opacity-60 hover:opacity-100 transition-opacity duration-300 cursor-pointer"
              onClick={goBack}
            >
              <i className="fas fa-arrow-left"></i>
            </span>
            <div
              onClick={() => handleNavigate("/")}
              className="flex cursor-pointer items-center pt-0 pb-0 sm:ml-0 w-[130px] h-[90px]"
              to="/"
            >
              <img
                src="https://cyberking.vn/wp-content/uploads/2023/08/logo-cyberking-trang.png"
                alt="logo"
                className="lg:ml-8 md:ml-1"
              />
            </div>
            <div className="flex flex-wrap items-center order-1 mx-8">
              {user ? (
                <ConfigProvider>
                  <Popover
                    placement="bottom"
                    arrow={false}
                    overlayInnerStyle={{
                      padding: 0,
                      marginRight: 20,
                      marginTop: 23,
                      background: "none",
                    }}
                    content={<AccountDropdown />}
                    trigger="hover"
                  >
                    <div className="flex items-center text-white bg-transparent backdrop-blur-3xl">
                      <span className="mr-3">
                        <img
                          src={accountIcon}
                          alt="account"
                          className="h-9 w-9"
                        />
                      </span>
                      <div className="flex items-center justify-center">
                        {user?.is_vip === "0" ? (
                          <div className="flex items-center">
                            <span className="text block font-medium truncate max-w-[120px] mr-2">
                              {user.account}
                            </span>
                            <span>|</span>
                            <span className="text-[#7ED0FF] font-medium ml-2">
                              {user.xu && formatCurrency(user.xu)} đ
                            </span>
                          </div>
                        ) : (
                          <div className="flex-col">
                            <div className="flex">
                              <span className="text block font-medium truncate max-w-[120px] mr-2">
                                {user.account}
                              </span>
                              <span>|</span>
                              <span className="text-[#7ED0FF] ml-2 font-medium">
                                {formatCurrency(user.xu)} đ
                              </span>
                            </div>
                            <div className="ml-0">
                              <span
                                className="text-base font-bold"
                                style={{
                                  background:
                                    "linear-gradient(to right, #D3E8FF, #7C93FF)",
                                  WebkitBackgroundClip: "text",
                                  color: "transparent",
                                }}
                              >
                                {user?.is_vip &&
                                  getVipRewardStatus(user?.is_vip)}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </Popover>
                </ConfigProvider>
              ) : (
                <div
                  onClick={() => handleNavigate("/login")}
                  className="btn cursor-pointer text-white flex items-center"
                >
                  <span className="mr-1">
                    <img
                      src={accountIcon}
                      alt="login"
                      className="h-9 w-9 mx-2"
                    />
                  </span>
                  Đăng nhập
                </div>
              )}
            </div>
            <ul className="flex ms-auto pl-2">
              {navItems.map((item, index) => (
                <li className="px-2" key={index}>
                  <div
                    onClick={() => handleNavigate(item.link)}
                    className={`font-medium text-[18px] relative cursor-pointer py-8 ${
                      location.pathname === item.link
                        ? "text-[#3B82F6] hover:text-[#3B82F6] !important before:w-[50%] after:w-[50%]"
                        : "text-white hover:text-[#3B82F6] !important"
                    } transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-300 before:absolute before:bg-[#3b82f6] before:origin-center before:h-[2px] before:bottom-0 before:left-[50%] hover:before:w-[50%] after:transition-[width] after:ease-in-out after:duration-300 after:absolute after:bg-[#3b82f6] after:origin-center after:h-[2px] after:w-0 hover:after:w-[50%] after:bottom-0 after:right-[50%]`}
                  >
                    {item.title}
                  </div>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      ) : null}
    </>
  );
};

export default Header;
