import { useQuery } from "@tanstack/react-query";
import { queryContants } from "../../../constant";
import { getAccountOrder, getPinAndBuyOrder } from "../../../apis/orders";

export const useGetAccountQuery = (account_id) => {
  const accessToken = localStorage.getItem("accessToken");
  return useQuery({
    queryKey: [queryContants.GET_ALL_ORDERS, account_id],
    queryFn: () => getAccountOrder({ id: account_id }),
    enabled: !!accessToken && !!account_id,
  });
};

export const useGetPinAndBuyOrderQuery = (userId, steamId, username, isVip) => {
  const accessToken = localStorage.getItem("accessToken");
  return useQuery({
    queryKey: [queryContants.GET_ALL_PIN_ORDER, userId, isVip],
    queryFn: () => getPinAndBuyOrder({ userId, steamId, username }),
    enabled: !!accessToken && !!userId && !!steamId,
  });
};
