import React, { useContext, useState } from "react";
import { Tooltip } from "antd";

import { StateContext } from "../../../context/reducer";
import { useAmountHistoryQuery } from "../../../store/queries/payment/payment.query";
import VipItem from "./VipItem";
import { VipDataMock } from "../../../constant";
import Diamond from "../../../assets/icons/vip/Diamond.png";
import PopupConfirm from "../../PopupConfirm/PopupConfirm";
import { useBuyVipMutation } from "../../../store/mutations/payment/payment.mutate";
import PopupError from "../../PopupConfirm/PopupError";
import { useGetVipQuery } from "../../../store/queries/auth.query";

const UpgradeVIP = () => {
  const { state } = useContext(StateContext);
  const { user } = state;
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
  const [rewardMessage, setRewardMessage] = useState("");
  const { data: vipAllData } = useGetVipQuery();

  const vipData = vipAllData
    ? vipAllData.filter((item) => item.is_show !== 0)
    : VipDataMock;
  const vipInitData = vipData.map((item) => ({
    key: item.id,
    price: item.reward_price,
  }));

  const { data: amountData } = useAmountHistoryQuery({
    userId: user?.id,
    vipData: vipInitData,
  });

  const { mutateAsync: buyVipMutate } = useBuyVipMutation();

  const getRewardStatus = (key) => {
    const a = amountData?.rewardsData?.find((item) => item.key === key);
    return !!a ? a.status : false;
  };

  const amountCharged = amountData?.amount ?? 0;

  const getMilestonePosition = (amount) => {
    if (amount <= 200000) {
      return (amount / 200000) * 25;
    } else if (amount <= 1500000) {
      return 25 + ((amount - 200000) / (1500000 - 200000)) * 25;
    } else {
      return 50 + ((amount - 1500000) / (15000000 - 1500000)) * 50;
    }
  };

  const progressPercentage = Math.min(getMilestonePosition(amountCharged), 100);

  const getReward = async (key, name, period) => {
    try {
      const res = await buyVipMutate({
        userId: user?.id,
        vip: key,
        times: period,
      });
      if (res) {
        setIsPopupVisible(true);
        setRewardMessage(`Chúc mừng bạn đã nhận được ${name}`);
      }
    } catch (error) {
      setIsErrorPopupVisible(true);
      setRewardMessage(`Đã có lỗi xảy ra!`);
    }
  };

  const renderProgressBar = () => {
    return (
      <div className="mt-12 relative mx-auto">
        {/* Thanh progress bar */}
        <Tooltip title={`Đã nạp: ${amountCharged} đ`} placement="topLeft">
          <div className="relative h-4 bg-gray-300 rounded-full">
            <div
              className="absolute h-4 bg-blue-500 rounded-full"
              style={{ width: `${progressPercentage}%` }}
            ></div>

            {/* Các icon hộp quà và các gạch chân */}
            {vipData.map((item, index) => (
              <div key={index}>
                {/* Icon hộp quà */}
                <Tooltip
                  title={`Nạp tích luỹ ${JSON.parse(
                    item.reward_price
                  ).toLocaleString()}đ để nhận ${item.name}`}
                  placement="top"
                >
                  <div
                    className={`absolute top-[-40px] flex flex-col items-center opacity-70 hover:opacity-100 ${
                      getRewardStatus(item.id) ? "cursor-pointer" : ""
                    }`}
                    onClick={() =>
                      getRewardStatus(item.id) &&
                      getReward(item.id, item.name, item.period)
                    }
                    style={{
                      left: `calc(${getMilestonePosition(
                        item.reward_price
                      )}% - ${index + 1 < vipData.length ? "16px" : "30px"})`,
                    }}
                  >
                    {<img src={item.gift_img} alt={item.name} />}
                  </div>
                </Tooltip>

                {item.reward_price > 0 && item.reward_price < 15000000 && (
                  <div
                    className="absolute top-0 h-4 border-[1px] border-white"
                    style={{
                      left: `calc(${getMilestonePosition(
                        item.reward_price
                      )}% - 3px)`,
                    }}
                  ></div>
                )}
              </div>
            ))}
          </div>
        </Tooltip>

        <div className="relative flex mt-2 mx-4">
          {vipData.map((item, index) => (
            <div
              key={index}
              className="absolute flex items-center"
              style={{
                left: `calc(${getMilestonePosition(
                  item.reward_price
                )}% - 40px)`,
              }}
            >
              <div
                className={`text-base flex text-white font-semibold text-center ${
                  index + 1 < vipData.length ? "w-24" : "w-[98px]"
                }`}
              >
                {parseInt(JSON.parse(item.reward_price), 10).toLocaleString(
                  "de-DE"
                )}
                <div>đ</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="sm:px-5 md:px-10 lg:px-15 box-border">
      <div>
        <h2 className="flex items-center text-lg justify-center">
          Nạp tích lũy và đổi thưởng gói
          <p className="flex items-center text-lg px-1 font-semibold bg-gradient-to-b from-blue-200 to-blue-400 bg-clip-text text-fill-transparent">
            VIP kim cương <img src={Diamond} alt="" className="h-10 w-10" />
          </p>
          vĩnh viễn
        </h2>

        {renderProgressBar()}
      </div>

      <div className="mt-12 grid grid-cols-2 gap-x-4 gap-y-4">
        {vipData.map((item, index) => {
          return (
            <div
              className={`${(index + 1) % 3 === 0 ? "col-span-2" : ""}`}
              key={index}
            >
              {(index + 1) % 3 === 0 && (
                <div className="w-full h-[1px] bg-[#565656] mb-4" />
              )}
              {!!user && <VipItem data={item} user={user} vipData={vipData} />}
            </div>
          );
        })}
      </div>

      <PopupConfirm
        visible={isPopupVisible}
        onClose={() => setIsPopupVisible(false)}
        title={rewardMessage}
        buttons={[
          {
            label: "Đóng",
            className:
              "px-4 py-2 text-sm font-medium text-white bg-[#65C4DF] border border-gray-300 rounded-[30px] shadow-sm opacity-70 hover:opacity-100",
            onClick: () => setIsPopupVisible(false),
          },
        ]}
      />
      <PopupError
        visible={isErrorPopupVisible}
        onClose={() => setIsErrorPopupVisible(false)}
        title={rewardMessage}
        buttons={[
          {
            label: "Đóng",
            className:
              "px-4 py-2 text-sm font-medium text-white bg-[#FF6D6D] border border-gray-300 rounded-[30px] shadow-sm opacity-70 hover:opacity-100",
            onClick: () => setIsErrorPopupVisible(false),
          },
        ]}
      />
    </div>
  );
};

export default UpgradeVIP;
