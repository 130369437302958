import React from "react";
import PricavyImg from "../../assets/images/PrivacyImg.jpg";

const Privacy = () => {
  return (
    <div className="w-full h-full p-10 text-black overflow-auto scroll no-scrollbar">
      <div className="w-full text-center text-[30px] font-[800]">
        Chính sách phần mềm CyberKing App
      </div>
      <div className="text-xl font-semibold mt-4">
        1. Thông tin chính sách bảo hành, bảo mật, dịch vụ, đổi trả, hoàn hủy… .
      </div>

      <div className="text-lg font-semibold mt-4">Chính sách bảo mật:</div>
      <ul className="list-disc pl-5 space-y-2">
        <li>
          Chúng tôi cam kết sẽ bảo mật toàn bộ thông tin người dùng khi bạn sử
          dụng dịch vụ của chúng tôi.
        </li>
      </ul>

      <div className="text-lg font-semibold mt-4">
        Chính sách sử dụng dịch vụ thuê tài khoản game:
      </div>
      <ul className="list-disc pl-5 space-y-2">
        <li>
          Ban vĩnh viễn những user lạm dụng bug, lợi dụng lỗ hổng hệ thống và
          những hành vi mang tính chất trục lợi cá nhân.
        </li>
        <li>
          Ban vĩnh viễn những user sử dụng hack, cheat, và những phần mềm bên
          thứ ba can thiệp vào tài khoản game, hệ thống, … .
        </li>
        <li>
          Yêu cầu khách hàng sử dụng dịch vụ kiểm tra kỹ số tiền nạp trước khi
          nạp, hệ thống sẽ không nhận hoàn tiền về ngân hàng hoặc ví khi đã nạp
          tiền thành công.
        </li>
      </ul>

      <div className="text-lg font-semibold mt-4">Chính sách bảo hành:</div>
      <ul className="list-disc pl-5 space-y-2">
        <li>
          Người dùng sẽ được hoàn trả số dư về lại trên app nếu như đã thuê tài
          khoản game và gặp lỗi “Đăng nhập vào game” không thành công.
        </li>
        <li>
          Người dùng sẽ được hệ thống tự động thay thế tài khoản game thành một
          tài khoản khác chứa game tương tự nếu đã mua gói thuê tài khoản game
          đó vĩnh viễn và trong lúc sử dụng tài khoản game gặp lỗi “Đăng nhập
          vào game” không thành công.
        </li>
      </ul>

      <div className="text-xl font-semibold mt-4">
        2. Thông tin mô tả sản phẩm dịch vụ + giá VNĐ.
      </div>
      <p className="mt-2">
        CyberKing App là phần mềm cho thuê tài khoản game bản quyền tại Việt
        Nam, cung cấp cho người dùng thuê và trải nghiệm hơn 1000+ game bản
        quyền khác nhau trên hầu hết mọi loại nền tảng nổi tiếng và uy tín nhất.
      </p>

      <div className="text-lg font-semibold mt-4">
        Mô tả sản phẩm + khoảng giá:
      </div>
      <ul className="list-disc pl-5 space-y-2">
        <li>Sản phẩm: Tài khoản game bản quyền.</li>
        <li>Khoảng giá thuê: 1.000 vnd{`=>`} 20.000 vnd / 1 tiếng.</li>
      </ul>
      <img className="rounded-lg mt-5" src={PricavyImg} alt="" />
    </div>
  );
};

export default Privacy;
