import { contextConstant } from "./constant";

export const getMe = (userInfor) => {
  return {
    type: contextConstant.GET_ME,
    payload: {
      user: userInfor,
    },
  };
};

export const getRentStatus = (rentStatus) => {
  return {
    type: contextConstant.GET_RENTS_STATUS,
    payload: rentStatus,
  };
};
export const getTimesLeft = (timesLeft) => {
  return {
    type: contextConstant.GET_TIMES_LEFT,
    payload: timesLeft,
  };
};
