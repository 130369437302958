import React, { useState } from "react";
import DescriptionWithStars from "./DecriptionWithStars";
import PopupConfirm from "../../PopupConfirm/PopupConfirm";
import {
  useBuyVipMutation,
  useUpdateVipMutation,
} from "../../../store/mutations/payment/payment.mutate";
import PopupError from "../../PopupConfirm/PopupError";
import { useGetVipOrderQuery } from "../../../store/queries/payment/payment.query";

const VipItem = ({ data, user, vipData }) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isSuccessPopupVisible, setIsSuccessPopupVisible] = useState(false);
  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
  const [isRenewPopupVisible, setIsRenewPopupVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [contentMessage, setContentMessage] = useState("");

  const { mutateAsync: buyVipMutate } = useBuyVipMutation();
  const { mutateAsync: updateVipMutate } = useUpdateVipMutation();
  const { data: vipOrderData } = useGetVipOrderQuery({
    userId: user?.id,
  });

  const handleBuyVip = async () => {
    try {
      setIsPopupVisible(false);

      if (user.xu < data.price) {
        setMessage(`Số dư không đủ để mua ${data.name}`);
        setIsErrorPopupVisible(true);
        return;
      }

      const currentVip = vipData.find(
        (item) => item.id === JSON.parse(user?.is_vip)
      );

      if (currentVip) {
        setIsRenewPopupVisible(true);
      } else {
        const res = await buyVipMutate({
          userId: user?.id,
          vip: data.id,
          amount: data.price,
          times: data.period,
        });

        if (res) {
          setMessage(`Bạn đã mua ${data.name} thành công.`);
          setIsSuccessPopupVisible(true);
        }
      }
    } catch (error) {
      setMessage(`Mua ${data.name} không thành công.`);
      setIsErrorPopupVisible(true);
    }
  };

  const handleRenewVip = async () => {
    try {
      setIsRenewPopupVisible(false);
      const res = await updateVipMutate({
        id: vipOrderData?.data.id,
        times: data.period,
        vip: data.id,
        amount: data.price,
        userId: user?.id,
      });

      if (res) {
        setMessage(`Bạn đã gia hạn ${data.name} thành công.`);
        setIsSuccessPopupVisible(true);
      }
    } catch (error) {
      setMessage(`Gia hạn ${data.name} không thành công.`);
      setIsErrorPopupVisible(true);
    }
  };

  const handleOpenVip = () => {
    const currentVip = vipData.find(
      (item) => item.id === JSON.parse(user?.is_vip)
    );

    if (!currentVip) {
      setIsPopupVisible(true);
      setMessage(`Xác nhận!`);
      setContentMessage(`Nhận ${data.name} vào tài khoản?`);
      return;
    }

    const currentVipPrice = JSON.parse(currentVip.price);
    const datePrice = JSON.parse(data.price);

    if (currentVipPrice === datePrice) {
      setIsRenewPopupVisible(true);
      return;
    } else if (currentVipPrice > datePrice) {
      setMessage(`Bạn đã có ${currentVip.name}, không thể mua ${data.name}.`);
      setIsErrorPopupVisible(true);
      return;
    } else {
      setIsPopupVisible(true);
      setMessage(`Xác nhận!`);
      setContentMessage(`Nhận ${data.name} vào tài khoản?`);
    }
  };

  const currentVip = vipData.find(
    (item) => item.id === JSON.parse(user?.is_vip)
  );
  const buttonLabel = currentVip?.id === data.id ? "Gia hạn" : "Mua";

  const title = data && JSON.parse(data.title);

  return (
    <div
      className="w-full h-full px-5 py-2 rounded-xl flex justify-center items-center"
      style={{
        background: "linear-gradient(180deg, #504f82 0%, #2d2d48 100%)",
      }}
    >
      <div className="max-w-[550px]">
        <div className="flex justify-center py-2">
          <p
            className={`flex items-center px-1 text-[20px] font-semibold bg-clip-text text-fill-transparent ${data.color}`}
          >
            {data.name}
            <img src={data.img} alt="" className="h-7 w-7 ml-1" />
          </p>
        </div>
        <div className="sm:px-1 md:px-2 lg:px-3">
          {title &&
            title.map((title, index) => {
              return (
                <div key={index}>
                  <DescriptionWithStars text={title} />
                </div>
              );
            })}
        </div>
        <div className="flex justify-center sm:pb-2 sm:pt-4 md:pt-6 lg:pt-8">
          <button
            className="bg-[#0E6FAD] p-2 rounded-2xl text-white font-semibold opacity-90 hover:opacity-100"
            onClick={() => {
              handleOpenVip();
            }}
          >
            {buttonLabel} {JSON.parse(data.price).toLocaleString("de")}đ
          </button>
        </div>
        <PopupConfirm
          visible={isPopupVisible}
          onClose={() => setIsPopupVisible(false)}
          title={message}
          content={contentMessage}
          buttons={[
            {
              label: "Mua Ngay",
              className:
                "px-4 py-2 text-sm font-medium text-white bg-[#1BBFEE] border border-transparent rounded-[30px] shadow-sm opacity-70 hover:opacity-100",
              onClick: handleBuyVip,
            },
            {
              label: "Đóng",
              className:
                "px-4 py-2 text-sm font-medium text-white bg-[#65C4DF] border border-gray-300 rounded-[30px] shadow-sm opacity-70 hover:opacity-100",
              onClick: () => {
                setIsPopupVisible(false);
              },
            },
          ]}
        />
        <PopupConfirm
          visible={isRenewPopupVisible}
          onClose={() => setIsRenewPopupVisible(false)}
          title={"Xác nhận gia hạn VIP"}
          content={`Bạn đã có ${data.name}. Bạn có muốn gia hạn thêm không?`}
          buttons={[
            {
              label: "Gia hạn",
              className:
                "px-4 py-2 text-sm font-medium text-white bg-[#1BBFEE] border border-transparent rounded-[30px] shadow-sm opacity-70 hover:opacity-100",
              onClick: handleRenewVip,
            },
            {
              label: "Đóng",
              className:
                "px-4 py-2 text-sm font-medium text-white bg-[#65C4DF] border border-gray-300 rounded-[30px] shadow-sm opacity-70 hover:opacity-100",
              onClick: () => setIsRenewPopupVisible(false),
            },
          ]}
        />
        <PopupConfirm
          visible={isSuccessPopupVisible}
          onClose={() => setIsSuccessPopupVisible(false)}
          title={"Mua VIP thành công!"}
          content={message}
          buttons={[
            {
              label: "Đóng",
              className:
                "px-4 py-2 text-sm font-medium text-white bg-[#65C4DF] border border-gray-300 rounded-[30px] shadow-sm opacity-70 hover:opacity-100",
              onClick: () => setIsSuccessPopupVisible(false),
            },
          ]}
        />
        <PopupError
          visible={isErrorPopupVisible}
          onClose={() => setIsErrorPopupVisible(false)}
          title={"Cảnh báo!"}
          content={message}
          buttons={[
            {
              label: "Đóng",
              className:
                "px-4 py-2 text-sm font-medium text-white bg-[#65C4DF] border border-gray-300 rounded-[30px] shadow-sm opacity-70 hover:opacity-100",
              onClick: () => setIsErrorPopupVisible(false),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default VipItem;
