import axios from "axios";
import { message } from "antd";

class AxiosClient {
  constructor(baseurl) {
    this.axiosClient = axios.create({
      baseURL: baseurl,
      timeout: 20000,
      headers: {
        contentType: "application/json",
      },
      withCredentials: false,
    });

    this.initializeRequestInterceptor();
    this.initializeResponseInterceptor();
  }

  initializeRequestInterceptor() {
    this.axiosClient.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem("accessToken");
        if (token && config.headers)
          config.headers["Authorization"] = `Bearer ${token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  initializeResponseInterceptor() {
    this.axiosClient.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const { data, status } = error?.response || {};
        const token = localStorage.getItem("accessToken");
        if (token && status === 401 && data.message === "Unauthorized") {
          message.destroy();
        }
        return Promise.reject(error);
      }
    );
  }
}

export default AxiosClient;
